<script setup>
// Recomend to use component with Dynamic import. It's hevy-weight
import { ref, defineModel, onMounted, computed, watch } from "vue";
import zxing from "zxing-browser";

import { MBottomSheet, MContainer, MContent, MAlert } from 'o365-mobile';
import { alert } from 'o365-vue-services';

// Conneceting to parent v-model prop
const model = defineModel();

const codeReader = new zxing.BrowserQRCodeReader();
const video = ref(null);

// type which respond for handling 
// redirect for oct and for qr diffrently. 
const redirectType = {
    "ocr": "ocr", 
    "qr": "qr"
};

// #region ComponentState
let decodingProcess = null;
let isOcrProcessing = ref(false);
let isScanProcessing = computed(() => dsObjects.state.isLoading || dsURLs.state.isLoading );

let url = ref("");
// same as regular url variable, but for handling takeapicture funciton
let ocrUrl = ref(null);

let isIncomingUrlhasSameOrigin = ref(false);

let objectId = ref("");
let systemAppId = ref(null);
// #endregion

// ds for checking on Object existance if scanner got a link which heads to /nt/resolve
const dsObjects = $getOrCreateDataObject({
    id: "dsObjects",
    viewName: 'atbv_Assets_Objects',
    distinctRows: false,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    selectFirstRowOnLoad: true,
    fields: [
        { "name": "ID" },
        { "name": "PrimKey" },
        { "name": "Name" }
    ],
    maxRecords: 1
});

const dsURLs = $getOrCreateDataObject({
    id: "dsURLs",
    viewName: "stbv_System_Urls",
    distinctRows: false,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    fields: [
        { "name": "ApplicationID" },
        { "name": "Type" }
    ],
    maxRecords: 1
});

// Scanner functionality, emits on BottomSheet showned. 
const startScan = () => {
    try {            
        decodingProcess = codeReader.decodeFromVideoDevice(null, video.value, async (result, err) => {
            
            if (result) {
                // if QR contains no object - show as target URl instead of object name
                const isScanedObjectExist = await handleScan(result.text);
                url.value ??  result.text;
                
                if (dsObjects.current?.PrimKey) { 
                    redirect(redirectType.qr, isScanedObjectExist);

                    return decodingProcess.then(proc => { proc.stop(); url.value = null; });
                }
            }
            if (err) { 
                // console.warn(err);
            }
        });
    } catch (error) {
        console.error(`Qr was recognized but something went wrong with reading: ${error}` );
    }
}

const redirect = (type, isObjectExists) => {
    if (!isObjectExists) {
        return;
    } 

    if (type === redirectType.qr && isObjectExists) {
        return window.location.href = '/nt/resolve-url' + systemAppId.value + objectId.value; 
    } else if (redirectType.ocr) {
        if (ocrUrl.value) {
            return window.location.href = ocrUrl.value; 
        }
    } else {
        console.warn("redirect function was used wrong!");
    }

    return;
}

// Emits
// stop web camera reming process and 
const onHide = () => decodingProcess.then(proc => { proc.stop(); url.value = null; });

const onShow = () => startScan();

// make following url as it should to be 
const handleScan = async (url) => {
    // isScanProcessing.value = true;
    // checks the provided url param is that a real URL;
    let incomingUrl;

    try {
        incomingUrl = new URL(url);
    } catch(e) {
        alert("QR which was provided contains a corrupted URL", "danger", { autohide: 3000 });
        return undefined;
    }

    // setting url check variables to default values on not initial scan. 
    // done these checks after check on url cause if provided url isn't ok 
    // we don't need to do anything at all
  
    if (isIncomingUrlhasSameOrigin.value) {
        isIncomingUrlhasSameOrigin.value = false
    }

    const appDomain = incomingUrl.origin;
    const isSameDomain = checkIfDomainIsSame(appDomain);

    if (isSameDomain)  {
        return undefined; 
    }

    systemAppId.value = findSystemApplicationId(url);
    // object type uses to get a comlumn name for filtering it on dsObject 
    const objectType = await  getSystemApplicationType(systemAppId.value);

    if(!objectType) {
        alert("System app hasn't been found, repeat or report about error.", "danger", {autohide: 4000});

        return undefined;
    }

    const isObjectExists = await checkIfObjectExists(url, objectType);
    
    if (!isObjectExists && !isIncomingUrlhasSameOrigin.value) {
        throw new Error("The object is not exist or incoming url has same origin.");
    } else {
        return true;
    }
}

// objectType param that contains a dsObjects table column name.
// The param will be used in whereClause
const checkIfObjectExists = async (url, objectType) => {
    if(!systemAppId.value) {
        return undefined;
    } else {
        const appIndex = url.lastIndexOf(systemAppId.value);
        const appId = url.slice((appIndex + systemAppId.value.length));
        const appsTree = appId.split("/");
       
        // check if relative object path(../123/324/1..) has ONLY numbers.
        const isAppsHaveOnlyNumbers = areAllNumbers(appsTree);

        if (isAppsHaveOnlyNumbers) {
            const hasObjectFound = await getObjectIfExists(appId, objectType);

            if (hasObjectFound) {
                return true;
            }
        }
    }
}

const checkIfDomainIsSame = (appDomain) => appDomain === window.origin;

const getObjectIfExists =  async (appId, objectType) => {
    dsObjects.recordSource.filterString = `${objectType} = ${appId}`;

    await dsObjects.load();

    if (dsObjects.data.length > 0) {
        objectId.value = appId;

        return dsObjects.data[0];
    }
}

const getSystemApplicationType = async (systemAppId) => {
    // this check means systemAppId.length => 0
    // if param systemAppId has not been found - stop execution.
    if (!systemAppId) {
        console.error("systemApp hasn't been provided");
        return undefined;
    }
    
    // get rid of first and last charactres which are slashes (/)
    const systemId = parseInt(systemAppId.slice(1, -1));
    dsURLs.recordSource.filterString = `ApplicationID = ${systemId}`;
    
    const systemApp = await dsURLs.load();
 
    return systemApp[0].Type;
}

const areAllNumbers = (strings) => {
    return strings.every(str => {
        const num = Number(str);
        return Number.isFinite(num);
    });
}

const findSystemApplicationId = (url) => {
    // regex wich finds a number between slashes, as example - /414/, /8004/ 
    const findAppId = /\/\d+\//g;
    const matches = url.match(findAppId);
    
    if (matches) {
        return matches.length > 0 ? matches[0] : undefined;
    } 
}

// #region OCR
function getFileFromVideo(video) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
            let file = new File([blob], "ocr.png", { type: "image/png" })

            resolve(file);
        }, 'image/jpeg');

        setTimeout(() => {
            reject("timeout 20s");
        }, 20000);
    });
}

const sendFile = async (file) =>  {
    const formData = new FormData();

    formData.append("image", file, file.name);
    formData.append("options", JSON.stringify({
        Name: "test",
        Table: "atbv_Assets_Objects",
        Regex: ["(\\+\d+)?=\\d+\\.\\d+:\\d+-[A-Z]+\\.\\d+T/\\d+"],
        SelectColumns: "Name, ID, PrimKey, OrgUnit_ID", 
        WhereColumns: ["Name"],
    }));

    try {
        const request = await fetch( "/nt/api/azure/ocr", {
                method: "POST",
                body: formData,
                redirect: "follow",
            }
        );

        if (request.ok) {
            const data = await request.json();

            return data;
        } 

    } catch (err) {
        console.error(err);
    }
}

const takePicture = async () => {
    console.log("take picture")
    isOcrProcessing.value = true;

    const file = await getFileFromVideo(video.value);
    const response = await sendFile(file);
    
    if (response.regexMatches[0]) {
        let objectName = response.regexMatches[0].value;
        const correctedObjectName = objectName.replace("=", "");
        const object = await getObjectIfExists(`'${correctedObjectName}'`, "Name");

        if(object?.ID) {
            // url = object.Name;
            ocrUrl.value = '/nt/object-info?ID=' + object.ID;
            console.log(ocrUrl.value);
            redirect(redirectType.ocr, true)
            
            return undefined;  
        }  else {
            alert("Object hasn't been found, try one more time.", "danger", {autohide: 4000});
            isOcrProcessing.value = false;

            console.warn("Object hasn't been found");
            return undefined;
        }
    } else {
        alert("Object hasn't been found, try one more time.", "danger", {autohide: 4000});
        isOcrProcessing.value = false;

        console.warn("Object hasn't been found");
        return undefined;
    }
}

// #endregion 

window.addEventListener('pageshow', () => { 
    model.value = false; 
    isOcrProcessing.value = false 
    
    dsObjects.clearItems();
    url.value = null;
});


</script>

<template>
    <MBottomSheet fullscreen @hide = "onHide" @show = "onShow" v-model="model">
        <template #title>
            {{ $t(" Scanner ") }}
        </template>

        <template #body>
            <MContainer>
                <MContent>
                    <div style = "position: absolute; inset: 1rem;">
                        <video ref="video" class = "video"></video>
                    </div>
                </MContent>

                <div class="p-3 d-flex flex-column gap-3">
                    <button class=" w-100 btn btn-lg btn-primary text-truncate" :class="{ 'strike-through': url }"
                        disabled @click = "redirect(redirectType.qr)">
                        <template v-if="dsObjects.current?.Name || url">
                            {{ dsObjects.current?.Name ?? url ?? "" }}
                        </template>
                        <template v-else-if="!url && !dsObjects.current?.Name && !isScanProcessing">
                            {{ $t("No QR code found") }}
                        </template>
                        <template v-else-if ="isScanProcessing">
                            <span  class="spinner-border spinner-border-sm end-0 me-2" role="status" aria-hidden="true"></span>
                            <span class="mx-auto"> {{ $t("Handling scan")}} (QR)</span>
                        </template>
                    </button>
                    <button 
                        :disabled="isOcrProcessing" 
                        class="w-100 btn btn-lg btn-primary" 
                        @click="takePicture">
                            <span v-if="isOcrProcessing" class="spinner-border spinner-border-sm end-0 me-2" role="status" aria-hidden="true"></span>
                            <span class="mx-auto"> {{ $t("Take a picture")}} (OCR)</span>
                    </button>
                </div>
            </MContainer>
        </template>
    </MBottomSheet>
</template>

<style scoped>
.afm-container {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
}

.video-wrapper {
    padding: 1rem;
    display: flex;
    justify-content: center;
}

</style>